.footer
    $self: &

    background-color: $gray-200
    color: $soft-black

    svg
        fill: $soft-black
    
    /**
     * Mobile
     */
    @include media-breakpoint-down(lg)
        svg
          width: 296px         

    /**
     * Desktop
     */
    
    @include media-breakpoint-up(lg)  
        &__wrapper
            margin: 40px 0
        svg
            margin-bottom: 80px
            width: 932px  

    @include media-breakpoint-up(xl) 
        &__wrapper
            margin: 80px 0
    
    @include media-breakpoint-up(xxl) 
        svg
            width: 1340px