.funnel
    $self: &

    /**
     * All viewports
     */
    
    &__wrapper
        display: flex
        justify-content: space-between
    
    &__title
        font-family: $font-family-h
        font-size: 35px
        font-weight: normal
        text-align: center
    
    &__sub-title
        font-family: $font-family-h
        font-size: 20px
        font-weight: normal
        text-transform: uppercase
        margin: 0 auto
        -webkit-transition: 0.3s
        transition: 0.3s
    
    &__description
        font-family: $font-family-h
        font-weight: normal
    
    &__list
        display: flex
        justify-content: space-evenly
    
    &__item
        position: relative
        width: 223px
        height: 260px
        text-align: center

        svg
            position: absolute
            top: 92px
            right: 0
            width: 223px
            height: 196px
            -webkit-transform: translateY(-50%)
            transform: translateY(-50%)
            -webkit-transition: 0.3s
            transition: 0.3s
            z-index: -1

        &--o svg
            fill: $custom-blue

        &--v svg
            fill: $custom-orange
        
        &:hover
            cursor: pointer
            
            svg
                width: 275px
                height: 243px
                right: -26px
                cursor: pointer
            .funnel__sub-title
                margin-top: 40px
                
    /**
     * Mobile
     */

    @include media-breakpoint-down(lg)
        margin-bottom: 40px
        &__wrapper
            flex-wrap: wrap
            &--right
                margin: auto
        &__list
            flex-wrap: wrap
            gap: 60px
        &__description
            font-size: 14px
            text-align: center
            margin-bottom: 80px

    @include media-breakpoint-up(lg)
        &__wrapper
            flex-wrap: wrap
            &--left
                margin-bottom: 40px
        &__description
            font-size: 18px
            text-align: center
    
    /**
     * Desktop
     */
    
    @include media-breakpoint-up(xl)
        margin-bottom: 80px
        &__wrapper
            flex-wrap: nowrap
        &__title
            width: 470px
            font-size: 50px
            text-align: left
            margin-bottom: 40px
        &__description
            font-size: 20px
            text-align: left
