.reviews-slider
    $self: &

    /**
     * All viewports
     */
    
    &__slide
        display: flex
        justify-content: space-between
    
    &__title
        font-family: $font-family-h
        font-size: 20px
        font-weight: normal
        text-transform: uppercase
    
    /**
     * Mobile
     */
    @include media-breakpoint-down(lg)
        &__slide
            flex-wrap: wrap
        &__content
            padding-top: 20px

    /**
     * Desktop
     */

    @include media-breakpoint-up(lg)
        &__image-paste
            width: 269px!important
            margin: 0 auto
        &__slide
            padding: 80px 105px
