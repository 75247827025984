.mainmenu
    $self: &

    /**
     * All viewports
     */
    &__list
        font-family: $font-family-h
        font-size: 20px
        font-weight: 400
        line-height: normal

        li
            a
                position: relative
                display: block
                padding: 15px
                text-decoration: none
                color: $gray-500
                font-weight: 800
                text-transform: uppercase
                margin: 0 10px
                transition: all .5s
                &::after,
                &::before
                    transition: all .5s
                &::after
                    position: absolute
                    bottom: 0
                    left: 0
                    right: 0
                    margin: auto
                    width: 0%
                    content: '.'
                    color: transparent
                    background: $white
                    height: 1px
                &:hover
                    color: $white
                &:hover::after
                    width: 75%

            a,
            a:after,
            a:before
                transition: all .5s
            
            &.is-active
                a
                    color: $white
                    &::after
                        width: 75%

    /**
     * Mobile
     */
    @include media-breakpoint-down(lg)
        background-color: $white
        position: fixed
        top: 0
        left: 0
        bottom: 0
        overflow-y: auto
        padding: 20px
        transform: translateX(-100%)
        transition: transform 0.3s ease
        width: 260px
        z-index: 102

        &.is-active
            transform: translateX(0)
            transition: transform 0.3s ease            

        &__close-btn
            position: absolute
            top: 12px
            right: 12px
            font-size: 14px

        &__list
            font-weight: 500

            li
                a
                    color: $gray-600
                    &:hover,
                    &:focus,
                    &:active
                        color: $soft-black
                        text-decoration: none

                &:not(:last-child)
                    margin-bottom: 20px
                
                &.is-active
                    a
                        color: $soft-black

    /**
     * Desktop
     */
    @include media-breakpoint-up(lg)
        &__list
            display: flex
            justify-content: flex-end

            > #{$self}__item
                &:not(:last-child)
                    margin-right: 20px

                    @include media-breakpoint-up(xl)
                        margin-right: 40px

                > a
                    padding-top: 5px
                    padding-bottom: 5px

        &__item
            a
                color: $white
                position: relative
                display: block
                transition: color .2s ease

                &:hover
                    text-decoration: none
                    transition: color .2s ease