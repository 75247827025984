.promo
    $self: &
    position: relative

    /**
     * All viewports
     */
    &::before
        content: ""
        position: absolute
        top: -27px
        z-index: -1
        width: 100%
        height: 305px
        margin-bottom: -305px

        background-color: $soft-black
        background-image: url(../images/fish.png)        
        background-repeat: no-repeat
        background-position: 50% 100%
        background-size: contain
    
    &__title
        font-family: $font-family-h
        font-weight: normal

    &__slogan
        font-family: $font-family-h
        font-weight: normal
        line-height: normal
        text-transform: uppercase
    
    &__image
        position: relative
        order: 4
        margin: auto
        z-index: -1
    
    &__btn
        position: relative
        font-family: $font-family-h
        font-size: 20px
        font-weight: normal
        text-transform: uppercase
        border: none
        border-radius: 0
        width: 245px
        padding: 14px 0
        @include linear-gradient(left, $custom-blue 50%, $custom-orange 50%)    
        overflow: hidden   
        z-index: 1 
        -webkit-transition: all 1s ease
        -moz-transition: all 1s ease
        -o-transition: all 1s ease
        transition: all 1s ease

        &::after      
            content: ""
            position: absolute
            height: 0%
            left: 50%
            top: 50%
            width: 50%
            z-index: -1
            -webkit-transition: all 0.75s ease 0s
            -moz-transition: all 0.75s ease 0s
            -o-transition: all 0.75s ease 0s
            transition: all 0.75s ease 0s

            background: $primary
            opacity: .5
            -moz-transform: translateY(-50%) translateX(-50%) rotate(90deg)
            -ms-transform: translateY(-50%) translateX(-50%) rotate(90deg)
            -webkit-transform: translateY(-50%) translateX(-50%) rotate(90deg)
            transform: translateY(-50%) translateX(-50%) rotate(90deg)

        &:hover::after
            opacity: 1
            height: 600%

    /**
     * Mobile
     */
    @include media-breakpoint-down(lg)
        margin-top: -10px

        &__image
            width: 200px

        &::before
            top: 0
            height: 453px
            background-position: 20% 100%
            background-size: 170%
        
        &__title
            min-height: 104px
            padding-top: 20px
            font-size: 35px
            text-align: center
            color: $white

        &__slogan
            margin-bottom: 60px
            font-size: 16px 
            text-align: center
            color: $white
        
        &__btn
            display: block
            margin: 0 auto
            margin-bottom: 100px

    
    /**
     * Desktop
     */
    @include media-breakpoint-up(lg)
        margin-top: 100px
        margin-bottom: 0
        &::before            
            top: -252px
            left: 50%
            z-index: -1
            display: block
            width: 50%
            height: 696px
        
        &__title
            font-size: 36px  

        &__slogan
            margin: 50px 0
            font-size: 20px    
        
        &__image     
            top: -185px
            width: 242px
        
        &__image-paste            
            position: relative
            top: -45px
            width: auto
    
    @include media-breakpoint-up(xl)   
        margin-bottom: -173px
        & .container
            padding: 0 60px
        &__title
            font-size: 50px        
        &__image   
            top: -320px      
            width: 460px