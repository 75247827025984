.baa
    $self: &

    /**
     * All viewports
     */
      
    &__list
        display: flex
        justify-content: space-between
    
    &__item
        display: flex
        flex-direction: row
        flex-wrap: wrap
        justify-content: space-between
        z-index: 1
        background-color: $gray-200
    
    &__title
        font-family: $font-family-h
        font-weight: normal

    &__description
        font-size: 18px
        font-weight: normal
        border-bottom: 1px solid $gray-400
        padding-bottom: 20px

    &__link
        position: relative
        font-family: $font-family-h
        font-size: 20px
        font-weight: normal
        text-transform: uppercase
        text-decoration: none
        color: $soft-black
        cursor: pointer

        &:hover
            color: $soft-black
            &::after
                width: 35px
                background-image: url(./../images/icon-right-arrow-verylong.svg)

        &::after
          content: ""
          position: absolute
          top: 50%
          width: 24px
          height: 12px
          background: url(./../images/icon-right-arrow-long.svg) center no-repeat
          -webkit-transform: translateY(-50%)
          transform: translateY(-50%)
          -webkit-transition: .3s
          transition: .3s
    /**
     * Mobile
     */
    @include media-breakpoint-down(lg)
        margin-bottom: 40px
        &__list
            flex-wrap: wrap
            gap: 20px
        &__item
            margin: 0 -12px
            padding: 20px
            .baa__title
                font-size: 24px     
                margin: auto 
        &__image
            width: 45px
            height: 40px
        &__link
            margin: auto

    @include media-breakpoint-up(lg)
        margin-bottom: 40px
        &__list
            flex-wrap: wrap
            gap: 20px
        &__item
            margin: 0 -28px
            padding: 40px
        &__title
            margin-left: 80px
            margin-right: auto
            font-size: 36px
    /**
     * Desktop
     */
      
    @include media-breakpoint-up(xl)
        margin-bottom: 80px
        &__list
            gap: initial
        &__item
            width: 46.7%
            margin: 0
            padding: 40px

        &__image
            flex: 1

        &__title
            flex: 6
            font-size: 36px
            margin-left: 80px
        
        &__link::after
            left: 140px