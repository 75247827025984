@use "sass:math";

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
}

.icon-arrow-up {
    width: math.div(32, 32) * 1em;
}
.icon-bone {
    width: math.div(40, 33) * 1em;
}
.icon-braincircuit {
    width: math.div(40, 33) * 1em;
}
.icon-chevron-left {
    width: math.div(16, 16) * 1em;
}
.icon-chevron-right {
    width: math.div(16, 16) * 1em;
}
.icon-dumbbell {
    width: math.div(40, 32) * 1em;
}
.icon-heart {
    width: math.div(40, 40) * 1em;
}
.icon-heartpulse {
    width: math.div(40, 36) * 1em;
}
.icon-hexagon {
    width: math.div(40, 40) * 1em;
}
.icon-shieldcheck {
    width: math.div(40, 40) * 1em;
}
.icon-venusmars {
    width: math.div(40, 32) * 1em;
}
