.advantage
    $self: &

    /**
     * All viewports
     */

    &__title
        font-family: $font-family-h
        font-weight: normal
        span.cb
            color: $custom-blue
        span.co
            color: $custom-orange
      
    &__list
        display: flex
        justify-content: space-between
        flex-direction: row
        flex-wrap: wrap
    
    &__item
        position: relative
        margin-bottom: 26px
        z-index: 1   
    
    &__description
        position: relative
        min-height: 80px

        &::before            
            content: ""
            position: absolute
            top: -2px
            left: 0
            width: 60px
            height: 60px  
    
    &__item:nth-child(-n+4) &__description::before
        background-color: $custom-blue  

    &__item:not(:nth-child(-n+4)) &__description::before
        background-color: $custom-orange
    
    &__icon        
        position: absolute
        width: 50px
        height: 50px
        fill: $white
    
    /**
     * Mobile
     */
    @include media-breakpoint-down(lg)     
        margin-bottom: 40px  
        &__title
            margin-bottom: 40px
            font-size: 35px
            text-align: center
        &__description
            min-width: 200px
            margin-left: 100px
            &::before            
                top: 0
                left: -100px
                width: 80px
                height: 80px
        
        &__icon
            top: 15px
            left: -85px
    
    @include media-breakpoint-up(lg)
        &__title
            margin-bottom: 40px
        &__list
            justify-content: flex-start
            gap: 40px
            margin: auto
        &__item
            width: 30%

        &__item::after
            content: ""
            position: absolute
            top: -108px
            left: 120px
            z-index: -1
            font-family: $font-family-h            
            font-weight: 400
            font-size: 280px
            color: $gray-100
            
        &__item--braincircuit::after            
            content: "1"
        
        &__item--hexagon::after            
            content: "2"
        
        &__item--venusmars::after            
            content: "3"
        
        &__item--heart::after            
            content: "4"
        
        &__item--shieldcheck::after            
            content: "5"
        
        &__item--heartpulse::after            
            content: "6"
        
        &__item--dumbbell::after            
            content: "7"
        
        &__item--bone::after            
            content: "8"
        
        &__description
            width: 220px
            padding-top: 110px
            padding-left: 0
            font-size: 18px
            line-height: 24px
        
        &__description::before            
            top: 0
            width: 80px
            height: 80px
        
        &__icon
            top: 15px
            left: 15px
    /**
     * Desktop
     */
    @include media-breakpoint-up(xl)
        margin-bottom: 80px
        &__list
            gap: 60px
            margin: 0

        &__item
            width: 20.08% 

        &__title
            margin-bottom: 80px
            font-size: 50px

        &__item::after
            content: ""
            position: absolute
            top: -108px
            left: 120px
            z-index: -1
            font-family: $font-family-h            
            font-weight: 400
            font-size: 280px
            color: $gray-100
            
        &__item--braincircuit::after            
            content: "1"
        
        &__item--hexagon::after            
            content: "2"
        
        &__item--venusmars::after            
            content: "3"
        
        &__item--heart::after            
            content: "4"
        
        &__item--shieldcheck::after            
            content: "5"
        
        &__item--heartpulse::after            
            content: "6"
        
        &__item--dumbbell::after            
            content: "7"
        
        &__item--bone::after            
            content: "8"
        
        &__description
            width: 100%
            padding-top: 110px
            padding-left: 0
            font-size: 18px
            line-height: 24px
        
        &__description::before            
            top: 0
            width: 80px
            height: 80px
        
        &__icon
            top: 15px
            left: 15px