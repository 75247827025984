.reviews
    $self: &

    /**
     * All viewports
     */
    
    &__wrapper
        background-color: $gray-200
    
    &__title        
        font-family: $font-family-h
        font-weight: normal
      
    /**
     * Mobile
     */
    @include media-breakpoint-down(lg)
        margin-bottom: 40px
        &__title
            width: 100%
            font-size: 35px
            text-align: center
            margin-bottom: 20px
    
    
    @include media-breakpoint-up(lg)
        margin-bottom: 40px
    /**
     * Desktop
     */
    @include media-breakpoint-up(xl)
        margin-bottom: 80px
        &__title
            font-size: 50px
            margin-bottom: 40px
