.header
    /*background-color: $gray-400*/
    padding: 20px
    .row
        justify-content: space-between
    
    /**
     * Mobile
     */
    @include media-breakpoint-down(lg)
        padding: 20px
    
    @include media-breakpoint-up(xl)   
        padding: 50px
