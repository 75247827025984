.logo
    &__link
        color: $dark
        font-weight: 700
        text-decoration: none
        text-transform: uppercase
        font-size: 20px

    /**
     * Mobile
     */
    @include media-breakpoint-down(lg)
        .logo
            &__link
                img
                    width: 150px